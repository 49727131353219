/**
 *  ・クラス名
 *    サインインモデル
 *  ・概要
 *    サインイン画面構成に関連に必要な処理を記述
 *  ・更新履歴
 *    2021/06/28 新規作成 佐藤
 */
import BaseModel from './baseModel';
import { ApiData, GetItemSearch, GetItemsResponse } from '@/services/hexabase/types';
import { apiPayload } from '@/constants/signin';

export default class SigninModel extends BaseModel {
  /* eslint-disable @typescript-eslint/camelcase */
  private localPayload = {
    conditions: [] as Array<{ [key: string]: string | string[] }>,
    per_page: 0,
    page: 1,
    use_display_id: true,
    omit_total_items: true
  };

  constructor() {
    super();
  }

  /**
   * 管理マスタを取得しフロントで使用しやすい形に整形
   * @param appId - アプリケーションID
   * @param dId - データストアID
   * @returns 階層化（加工された）管理マスタ
   */
  public async getApplicationOptions(appId: string, dId: string) {
    const searchFields = await this.getItemSearchConditions(appId, dId);
    const searchParams = this.buildParams(searchFields);
    const options = await this.getItemSearch(appId, dId, searchParams);
    return this.createOptionsList(options.items) as { [proccess: string]: { [key: string]: any } };
  }

  /**
   * 管理マスタへ問い合わせる検索コンディションを作成します
   * @param searchFields - データストアフィールド情報
   * @returns - 検索コンディション
   */
  private buildParams(searchFields: GetItemSearch) {
    const searchFieldId = 'initial_read';
    const result = this.deepCopy(this.localPayload);
    const searchTarget = searchFields.result
      .filter(field => field.display_id === searchFieldId)[0]
      .options?.find(option => option.value === 'true');
    if (searchTarget !== undefined) {
      result.conditions = [{ id: searchFieldId, search_value: [searchTarget.option_id] }];
    }
    return result;
  }

  /**
   * DBより取得した管理マスタを加工します
   * @param options - 取得した管理マスタ
   * @returns - 加工済管理マスタ
   */
  createOptionsList(options: ApiData) {
    const result: { [proccess: string]: { [key: string]: any } } = {};

    if (options.length === 0) return result;

    for (const i in options) {
      let process = options[i].process;
      const display = options[i].screen;
      const key = options[i].definition_name;
      let val = null;

      if (options[i].type) {
        try {
          switch (options[i].type) {
            case 'int':
              val = Number(options[i].defined_value);
              if (isNaN(val)) val = null;
              break;
            case 'json':
              val = JSON.parse(options[i].defined_value);
              break;
            default:
              val = options[i].defined_value;
              break;
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (!this.isExsist(process) || !process) {
        process = 'common';
      }

      if (!(process in result)) result[process] = {};

      if (this.isExsist(display) && display) {
        if (!(display in result[process])) result[process][display] = {};
        result[process][display][key] = val;
      } else {
        result[process][key] = val;
      }
    }
    return result;
  }

  /**
   * パスワードリセット申請
   * パスワードリセット申請を送信します。
   * @param {string} email - リセット対象アドレス
   * @returns apiレスポンス
   */
  public async requestPassReset(email: string): Promise<{ valid_email: boolean }> {
    const payload = {
      ...apiPayload.forget,
      email: email
    };
    return await this.sendRequestPassword(payload);
  }

  /**
   * パスワード再設定
   * パスワード再設定リクエストを送信します。
   * @param {string} password - 新しいパスワード
   * @param {string} passwordConf - 新しいパスワード（確認用）
   * @param {string} token - 再設定token（id）
   * @returns null（空）
   */
  public async setNewPassword(
    password: string,
    passwordConf: string,
    token: string
  ): Promise<void> {
    const payload = {
      new_password: password,
      confirm_password: passwordConf,
      id: token
    };
    return await this.sendRequestPassword(payload, 'put');
  }
}
