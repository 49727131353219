























































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import SigninModel from '@/models/signinModel';
import {
  displayMessage,
  apiMessage,
  inputElement,
  passChangeSuccessRedirectName
} from '@/constants/signin';

type ShowIconType = {
  password: boolean;
  passwordConf: boolean;
};

@Component({
  components: { SingleText }
})
export default class ResetPassword extends Vue {
  private signinModel = new SigninModel();
  /** ユーザートークン */
  token = this.$route.params.token;

  /** 画面表示文言の定義オブジェクト */
  define = {};
  /** フォーム要素文言の定義オブジェクト */
  inputElement = {};

  /** フォーム入力: 新パスワード */
  password = '';
  /** フォーム入力: 新パスワード（再入力） */
  passwordConf = '';

  /** ローディングスピナー表示状態（true: 表示中） */
  isLoading = false;
  /** パスワードを見せるかどうか。デフォルトはfalse（見せない） */
  isShowPassword: ShowIconType = {
    password: false,
    passwordConf: false
  };
  /** 各種エラーメッセージ */
  errorMessage = '';

  created() {
    // 外部定義をプロパティーに追加
    this.define = displayMessage.resetPass;
    this.inputElement = inputElement.resetPass;
  }

  /**
   * パスワード更新リクエストを送信
   * @returns void
   */
  async requestChangePassword(): Promise<void> {
    try {
      this.errorMessage = '';
      this.isLoading = true;

      await this.signinModel.setNewPassword(this.password, this.passwordConf, this.token);
      this.$router.push({ name: passChangeSuccessRedirectName });
    } catch (e) {
      this.errorMessage = apiMessage.fail;
    } finally {
      this.isLoading = false;
    }
  }
}
