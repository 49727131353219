/**
 * サインイン画面関連定義ファイル
 * 概要
 *  サインイン画面関連（パスワードリセット画面、パスワード設定画面）で使用する各定義をまとめるファイル
 * 更新履歴
 *  2021/08/30 新規作成
 */
interface MessageDefine {
  forget: {
    main: { [k: string]: string };
    success: { [k: string]: string };
  };
  resetPass: {
    main: { [k: string]: string };
  };
}
interface InputElement {
  forget: {
    label: string;
    rules: string;
    icon: string;
  };
  resetPass: { [k: string]: string }[];
}

/**
 * パスワードリセット申請時Payload
 * host: 申請後リダイレクト先ドメイン
 * returnPath: 申請後リダイレクト先Path
 */
const host = 'http://localhost:8080';
const returnPath = 'signin/reset_password';
const templateId = '';

// パスワード再設定後リダイレクトページ
export const passChangeSuccessRedirectName = 'Signin';

// 各画面に表示する文言
export const displayMessage: MessageDefine = {
  // パスワード忘れた方
  forget: {
    main: {
      title: 'パスワードを忘れた方',
      desc:
        'リセットするアカウントのメールアドレスを送信してください。\nパスワード再設定のご案内メールを送信します。',
      buttonLabel: '送信'
    },
    success: {
      desc: 'パスワード再設定のご案内メールを送信いたしました。\nメールBOXをご確認ください。',
      linkLabel: 'サインイン画面へ'
    }
  },
  // パスワード再設定
  resetPass: {
    main: {
      title: 'パスワード再設定',
      desc: '新しいパスワードを設定してください。',
      buttonLabel: '送信'
    }
  }
};

// APIエラーメッセージ
export const apiMessage: { [k: string]: string } = {
  noValidEmail: '有効なメールアドレスではありません',
  fail: '時間をおいて再度送信してください'
};

// 各画面での入力値
export const inputElement: InputElement = {
  // パスワード忘れた方
  forget: {
    label: 'メールアドレス',
    rules: 'required|email',
    icon: 'mdi-email-outline'
  },
  // パスワード再設定
  resetPass: [
    { label: '新しいパスワード', rules: 'required', id: 'password', icon: 'mdi-lock-outline' },
    {
      label: '新しいパスワード(確認)',
      rules: 'required|confirmed:password',
      icon: 'mdi-lock-outline'
    }
  ]
};

/* eslint-disable @typescript-eslint/camelcase */
// APIリクエストPayload
export const apiPayload = {
  // パスワード忘れた方
  forget: {
    host: host,
    root_path: returnPath
  }
};
